
import './App.css';
import {Navbar24, Header2, Partners2,ServicesAnim, Projects2, About, Team, News, MultiChain, Careers2, Footer, Implementation} from './components';

function App() {
  return (
   <div>
    
     <Navbar24/>
    <Header2/>
    <Partners2/>
     <ServicesAnim/> 
    <Implementation />
    <Projects2/>
    <About/>
    <Team/>
    <MultiChain/>
    <News/>
    <Careers2/>
    <Footer/>
    
   </div>
  );
}

export default App;
