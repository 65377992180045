import React from 'react'
import './partners2.css'

import blade from "../../assets/images/partners/blade-blue.svg" 
import polygon from "../../assets/images/partners/polygon-brand.svg" 
import cosmos from "../../assets/images/partners/Frame-cosmos.svg" 
import avail from "../../assets/images/partners/avail-brand.svg" 
import informal from "../../assets/images/partners/Frame-informal.svg" 
import ponos from "../../assets/images/partners/Frame-ponos.svg" 
import apex from "../../assets/images/partners/apex-figma.svg" 
import silenceLaboratories from "../../assets/images/partners/silenceLaboratories.png"



function Partners2() {
  return (
    <section id='partners2'>
        <div className='container partners2-main'>

            <div className='partners2-title-main'>
                <h4>
                    <div className='partners2-title-wrapper'>Meet our partners</div>
                    </h4>
                <div className='partners2-subtitle'>Since 2020, we've been collaborating with leading blockchain projects.</div>

                
            </div>

             <div className='partners2-logo-main'>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://bladeblockchain.tech/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={blade} alt="blade" className={"partners2-filter-img-blade"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={polygon} alt="polygon" className={"partners2-filter-img-polygon"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://cosmos.network/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={cosmos} alt="cosmos" className={"partners2-filter-img-cosmos"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={avail} alt="avail" className={"partners2-filter-img-avail"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                     <div>
                        <a href="https://informal.systems/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={informal} alt="informal" className={"partners2-filter-img-informal"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://ponos.technology/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={ponos} alt="ponos" className={"partners2-filter-img-ponos"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={apex} alt="apex" className={"partners2-filter-img-apex"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://www.silencelaboratories.com/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={silenceLaboratories} alt="silenceLaboratories" className={"partners2-filter-img-sl"}/> 
                        
                        </a>
                    </div>
                 </div>
                    
                </div>
            
        </div>
    </section>
  )
}

export default Partners2